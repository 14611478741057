<template>
    <main>
      <div class="main-container">
        <div class="main-container-search">
          <el-row :gutter="10">
            <el-col :span="8">
              <el-select
                clearable
                @change="search.name=wasteNames.find(obj => obj.id === search.code.id);"
                class="searchPole"
                v-model="search.code"
                filterable
                remote
                value-key="id"
                reserve-keyword
                placeholder="Код отхода"
                :remote-method="searchCode"
              >
                <el-option
                  v-for="item in ss01CodeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="8">
              <el-select
                clearable
                @change="search.code=ss01CodeList.find(obj => obj.id === search.name.id);"
                class="searchPole"
                v-model="search.name"
                filterable
                remote
                value-key="id"
                reserve-keyword
                placeholder="Наименование"
                :remote-method="searchWasteName"
              >
                <el-option
                  v-for="item in wasteNames"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="4">
                <el-select
                    class="searchPole"
                    v-model="search.type"
                    value-key="id"
                    placeholder="Тип лота"
                >
                    <el-option
                    v-for="item in types"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                    >
                    </el-option>
                </el-select>
            </el-col>
            <el-col :span="4">
                <el-button @click="PostRegisterOfFacilitis">Поиск</el-button>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="main-container-second">
        <el-col :span="24">
          <div class="main-container-second-object">
            Действительные лоты
          </div>
          <!--<div class="main-container-list">
            <div
              class="main-container-list-inner"
              :key="key"
              v-for="(registerObject, key) in register"
            >
              <img src="../assets/MarkerSVG.svg" alt="marker" /> 
              <h5>Код отхода: {{ registerObject.ss01WasteDictionaryDTO.wasteCode }}</h5>
              <h5> Месторасположение: </h5>
              <p v-bind:style="{ backgroundColor: activeEl==key ? '#7ac750' : '#ffffff'}" @click="showModal(registerObject,key)">
                
                {{ registerObject.objectaddress }}</p>
            </div>
          </div>-->
          <div class="main-container-list">
            <div class="main-container-list-inner-location">
              <h5 class="main-container-list-inner-location-code">Код отхода: </h5>
              <h5 > Месторасположение: </h5>
            </div>
            <div
              class=""
              :key="key"
              v-for="(registerObject, key) in register"
            >
            <div  class="main-container-list-inner">
              <div class="main-container-list-inner-code">
                {{ registerObject.ss01WasteDictionaryDTO.wasteCode }}
              </div>
              <div v-bind:style="{ backgroundColor: activeEl==key ? '#7ac750' : '#d8d5d5'}" @click="showModal(registerObject,key)" class="main-container-list-inner-adress">
                {{ registerObject.objectaddress }}</div>
            </div>
            </div>
          </div>
        </el-col>
        <el-col :span="16">
          <div class="main-container-second-map-descr">
            Карта опубликованных лотов
          </div>
          <div class="main-container-second-map">
            <GmapMap
              :center="zenter"
              :zoom="7"
              map-type-id="terrain"
              style="width: 1100px; height: 1100px"
              @click="visible=false; marker= {lat: 1,lng: 1,}"
            >
              <GmapMarker
                :key="index"
                v-for="(m, index) in register"
                :position="m.position"
                :clickable="true"
                :draggable="true"
                @click="showModal(m,index)"

              />
              <gmap-custom-marker :marker="marker">

                <div
                  class="card"
                  v-if="visible"
                  @click="true"
                >
                  <div class="card-name">{{ tempLots.ss01WasteDictionaryDTO.wasteCode + "-" +tempLots.ss01WasteDictionaryDTO.wasteName }}</div>
                  <div class="card-name">{{ markerAdress }}</div>
                  <div class="card-adress">{{ markerMinPrise }} - {{ markerMaxPrise }} BYN</div>
                  <div class="card-phone">{{ markerMass }}</div> 
                  <el-row :gutter="10">
                    <el-button type="warning" @click="drawer = true">Откликнуться на лот</el-button>
                  </el-row>
                </div>
                <p style="text-align:center;margin-bottom: 0%;">
                  <!-- <img src="../assets/MarkerVaste.svg" /> -->
                </p>
              </gmap-custom-marker>
            </GmapMap>
          </div>
        </el-col>
      </div>
      <el-drawer
        title=""
        :visible.sync="drawer"
        :direction="direction"
        :before-close="1"
        size="25%">

          <h1 class="modal-info-h1">Заявка на лот №{{ bid.lot_id }}</h1>
        <div class="modal-info">
          <p>{{ tempLots.ss01WasteDictionaryDTO.wasteCode + "-" +tempLots.ss01WasteDictionaryDTO.wasteName}}</p>
          <p>{{ markerAdress }}</p>
          <p>{{ markerMinPrise }} - {{ markerMaxPrise }} BYN</p>
          <p>{{ markerMass }}</p>
        </div>

        <div>
          <h3 class="modal-info-h3">Предлагаемая цена</h3>
            <el-input
              v-model="bid.prise"
              placeholder="BYN"
              class="input-waste-code"
              />
            <!-- <p>BYN</p> -->
        </div>
        <div>
          <h3 class="modal-info-h3">Масса</h3>
          <el-input
                v-model="bid.mass"
                placeholder="Масса"
                class="input-waste-code"
                />
                <!-- <p>{{ markerMass.split(" ")[1] }}</p> -->
        </div>
        <div>
          <h3 class="modal-info-h3">Состав</h3>
                <el-input
                type="textarea"
                v-model="bid.composition"
                placeholder="Состав"
                class="input-waste-code"
                />
        </div>
       <div>
        <h3 class="modal-info-h3">Примечание</h3>
        <el-input
                type="textarea"
                v-model="bid.text"
                placeholder="Примечание"
                class="input-waste-code"
                />
       </div>

        <el-button type="danger" @click="addBid(bid)" style="padding:5px" class="input-waste-descr">Подтвердить</el-button>
      </el-drawer>
    </main>
  </template>

<style scoped>
.main-container {
  margin-top: 30px;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
  flex-direction: column;
}
body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
.main-container-select {
  color: #000;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 25px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 50px;
}
.main-container-search {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
  height: 70px;
}
.main-container-search-input {
  display: flex;
  align-items: center;
  color: #a5a5a5;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
input[type="search"] {
  box-shadow: 4px 4px 4px 4px rgba(19, 172, 53, 0.25);
  border-radius: 2px;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
  border: 3px solid #7ac750;
  background: rgba(122, 199, 80, 0);
  width: 300px;
}
input[type="submit"] {
  height: 20px;
  border-radius: 2px;
  color: white;
  background-color: #41bf2d;
  box-shadow: 4px 4px 4px 4px rgba(19, 172, 53, 0.25);
  cursor: pointer;
  border: 1px solid #7ac750;
}
select {
  color: white;
  background-color: #7ac750;
  font-size: 18px;
  box-shadow: 4px 4px 4px 4px rgba(19, 172, 53, 0.25);
  height: 50px;
  border-radius: 10px;
  border: 3px solid #7ac750;
  cursor: pointer;
}
.main-container-checkbox-inner {
  display: flex;
}
.main-container-checkbox {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border: 3px solid #7ac750;
  background: rgba(122, 199, 80, 0);
  border-radius: 5px;
  box-shadow: 4px 4px 4px 4px rgba(19, 172, 53, 0.25);
}
input[type="checkbox"] {
  margin-right: 10px;
  cursor: pointer;
}
.main-container-second {
  display: grid;
  grid-template-columns: 30% 70%;
  gap: 10px;
  justify-items: center;
  /* align-items: center; */
  background: #f8f8f8;
  font-size: 18px;
}
.main-container-second-object {
  background: #7ac750;
  color: white;
  background-color: #7ac750;
  font-size: 18px;
  box-shadow: 4px 4px 4px 4px rgba(19, 172, 53, 0.25);
  height: 50px;
  border-radius: 10px;
  border: 3px solid #7ac750;
  text-align: center;
  margin-top: 10px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-container-second-map-descr {

  width: 100%;
  color: #7ac750;
  background-color: white;;
  font-size: 18px;
  /* box-shadow: 4px 4px 4px 4px rgba(19, 172, 53, 0.25); */
  height: 50px;
  border-radius: 10px;
  /* border: 3px solid #7ac750; */
  /* text-align: center; */
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-container-list {
  background: #f8f8f8;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}
.main-container-list p {
  color: #0e3232;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}
.main-container-list-inner {
  display: flex;
  padding: 5px;
  padding-left: 10px;
  align-items: center;
}
.main-container-list-inner img {
  width: 25px;
  height: 25px;
  /* background-color: #349324;
  border-radius: 50%; */
  margin-right: 10px;
}
.main-container-list-inner p {
  width: 80%;
}
.main-container-second-map {
  background: #ccf8b3;
  border-radius: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.el-col-16 {
  width: 90%;
  box-shadow: 4px 4px 4px 4px rgba(19, 172, 53, 0.25);
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

#subregions {
  fill: #eeeeee;
  stroke: #ffffff;
  stroke-width: 0.5;
}
#regions {
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
}
circle {
  fill: #cccccc;
  stroke: none;
}

/* модалка на маркерах */
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #fafafa;
  padding: 15px;
  box-shadow: 3px 3px 3px grey;
  border: 1px solid #ccc;
  width: 300px;
  font-weight: bold;
}

.card-name {
  font-weight: bolder;
  padding-bottom: 5px;
  font-size: 12px;
}
.card-adress {
  padding-bottom: 5px;
  font-size: 10px;
}
.card-phone {
  padding-bottom: 5px;
  font-size: 10px;
}

.colored {
  background-color: #5650c7;
  border-radius: 10px;
  cursor: pointer;
}
.modal-info{
margin-left: 20px;
}
.el-drawer__header{
  margin-left: 50px;
}
.modal-info-h1{
  margin-left: 30px;
}
.modal-info-h3{
  margin-left: 20px;
}
.input-waste-code{
/* width: 200px; */
margin-left: 10px;
}
.input-waste-descr{
  margin-top: 30px;
  margin-left: 20px;
}
.main-container-list-inner-location{
display: flex;
font-size: 20px;
margin-top: 10px;
}
.main-container-list-inner-location-code{
margin-right: 30px;

}
.main-container-list-inner-code{
margin-right: 30px;
padding: 15px;
padding-left: 0px;
width: 10%;
}
.main-container-list-inner-data{
display: flex;
}
.main-container-list-inner-adress{
border-radius: 5px;
font-size: 16px;
width: 90%;
}
.main-container-list-inner{
  background-color: #f3f3f3;
  margin-top:5px ;
  margin-bottom:5px ;
}
</style>

<script>
import { AXIOS } from "@/AXIOS/http-common";

import { mapGetters } from "vuex";

import GmapCustomMarker from "vue2-gmap-custom-marker";

export default {
  components: {
    "gmap-custom-marker": GmapCustomMarker,
  },
  name: "maps",
  data() {
    return {
      tempLots:{
        ss01WasteDictionaryDTO:{
          wasteCode:null,
          wasteName:null
        }
    },
      drawer:false,
        bid:{prise:null,mass:null,text:null,composition:null,lot_id:null},
        types: [
        {
          id: 1,
          label: "Продажа",
        },
        {
          id: 2,
          label: "Покупка",
        },
      ],
      zenter:{ lat: 53.8845267, lng: 27.4282195 },
      wasteNames:[],
      ss01CodeList:[],
      activeEl:"",
      register: null,
      search: {
        code: "",
        name: "",
        alien: null,
        own: null,
        type: 1,
      },
      visible: false,
      marker: {
        lat: 1,
        lng: 1,
      },
      markerName: "",
      markerMass:"",
      markerAdress: "",
      // markerMinPrise:"",
      // markerMinPrise:"",
      list: [],
    };
  },
  methods: {

    async PostRegisterOfFacilitis() {
        if(this.search.type==1)
        AXIOS.post("register-of-facilities/searchInput",{alien:this.search.alien,own:this.search.own,code:this.search.code.name}).then((response) => {
          this.register = response.data;
          this.register.forEach(element => {
            element.position= { lat: element.lat, lng: element.lng };
            element.type=1;
          });
        });

        if(this.search.type==2)
        AXIOS.post("register-of-facilities/searchExport",{alien:this.search.alien,own:this.search.own,code:this.search.code.name}).then((response) => {
            this.register = response.data;
            this.register.forEach(element => {
              element.position= { lat: element.lat, lng: element.lng };
              element.type=2;
            });
            //this.register=this.register.concat(this.registerInput);
            //this.register=this.register.concat(this.registerExport);
            });
    },

    async searchCode(query) {
      await this.$store.dispatch("getCodeList", query);
      this.ss01CodeList=this.ss01CodeList0;
      this.wasteNames=[];
      this.ss01CodeList0.forEach(ss01Code => {
        this.wasteNames.push({id:ss01Code.id,name:ss01Code.name2});
      });
    },

    async searchWasteName(query) {
      await this.$store.dispatch("getWasteNames", query);
      this.wasteNames=this.wasteNames0;
      this.ss01CodeList=[];
      this.ss01CodeList.forEach(wasteName => {
        this.wasteNames.push({id:wasteName.id,name:wasteName.name2});
      });
    },
    addBid(lot)
    {
      let Bid = {lots_id:lot.lot_id,user_id:null,mass:parseFloat(lot.mass.replace(",", ".")),price:parseFloat(lot.prise.replace(",", ".")),done:null,
        cansel:null,Lots:null,text:lot.text,composition:lot.composition,
        adress:null,
        oblast:null};
      this.$store.dispatch("addBid", Bid).then(() => {
        
      this.drawer = false;
        this.notification("Успешно", "Заявка отправлена", "success");
        
      });
    },
    searchROF() {
      this.registerzero.forEach((registr, i) => {
        registr.waste.forEach((waste, j) => {
          let nuls = true;
          if (
            this.search.alien == null ? true : waste.alien == this.search.alien
          ) {
            if (this.search.own == null ? true : waste.own == this.search.own) {
              if (
                this.search.code == null || this.search.code.name === ""
                  ? true
                  : waste.ss01WasteDictionaryDTO.wasteCode ===
                    this.search.code.name
              ) {
                if (
                  this.search.name == null || this.search.name.name === ""
                    ? true
                    : waste.ss01WasteDictionaryDTO.wasteName ===
                      this.search.name.name
                )
                  nuls = false;
              }
            }
          }
          if (nuls) this.register[i].waste[j];
        });
      });
    },
    showModal(markerMap, index) {
      this.tempLots=markerMap;
      this.activeEl = index;
      this.visible = false;
      this.zenter=markerMap.position;
      this.marker = markerMap.position;
      this.markerAdress = markerMap.objectaddress;
      this.markerMass = markerMap.objectnumberPhone;
      this.markerMinPrise = markerMap.ownername;
      this.markerMaxPrise = markerMap.owneraddress;
      this.bid.lot_id=markerMap.id;
      // this.visible = !this.visible;
      this.visible = true;
    },
    closeModal() {
      // this.marker = markerMap.position;
      // this.markerAdress = markerMap.objectaddress;
      // this.markerName = markerMap.objectname;
      // this.visible = !this.visible;
      this.visible = false;
    },
    colored(event) {
      var list = document.querySelectorAll(".main-container-list-inner p");

      console.log(event.target); // пункт списка
      for (let elem of list) {
        elem.addEventListener("click", function () {
          this.classList.toggle("colored"); // добавляем абзацу класс
        });
      }
      this.list = list;
      // event.target.classList.add("colored");
    },
  },

  computed: {
    ...mapGetters({
      ss01CodeList0: "GETSSS01CODELIST",
      wasteNames0: "GETWASTENAMES",
      foundedWaste: "GETSS01DICTIONARY",
      organizationHierarchyByType: "GETORGANIZATIHIERARCHYBYTYPE",
    }),
  },

  async mounted() {
    //await this.PostRegisterOfFacilitis();
  },
};
</script>
