<template>
  <div>
    <maps></maps>
  </div>
</template>

<script>
import maps from "@/components/ecologist/wasteExchange/maps";

export default {
  name: "MapsView",
  components: { maps },
};
</script>

<style scoped></style>
